import { setOccupancyLimits, setMaxRooms } from './folks-selector.js'
import { confirm } from "./popups.js"

let request = null;
let triggerSearchAfterUpdate = false

const updatetriggerSearchAfterUpdateValue = (val) => triggerSearchAfterUpdate = val;

const updateSearchBarOptions = (context) => {
	var wrapper = context || $('.js-selector-destination-wrapper:visible').first() || $('.js-folks-selector-wrapper:visible').first(),
		destination = wrapper.find('.js-folks-selector-destination'),
		departing = wrapper.find('.js-folks-selector-departing'),
		duration = wrapper.find(".js-folks-selector-duration");

	// Update all destination selectors with the currently selected destination
	$('.js-folks-selector-destination').each(function() {
		$(this).val(destination.val())
	})

	getSearchBarOptions(
		wrapper.find('input,select'),
		departing,
		duration,
		true,
		wrapper);
}

const getSearchBarOptions = function(form, dateField, durationSelect, enable, folksSelectorWrapper) {

	var loader = folksSelectorWrapper.find(".js-loader");
	// show loader
	loader.show();
	// ajax
	request = $.ajax({
		url: "/api/search-block-options",
		data: form.serialize(),
		success: function(response) {
			response = JSON.parse(response);

			setSearchModuleOptions(response, dateField, durationSelect, enable, folksSelectorWrapper);

			// set the translated destination
			let selectedDestination;
			if ($('.js-destination-picker-input')[2]) selectedDestination = $('.js-destination-picker-input')[2].value;
			else selectedDestination = $('.js-destination-picker-input')[0].value;
			let translatedDestination = $("[data-destination-value = '" + selectedDestination + "']").first().text().trim();
			$('.js-destination-picker-facade').val(translatedDestination);

			// remove loader
			loader.hide();
			// only allow search update if response is success
			if (!response.Success && triggerSearchAfterUpdate) {
				triggerSearchAfterUpdate = false;
			}
		},
		error: function(e) {
			if (e.statusText != "abort") {
				confirm("Error", "Sorry, there was an error (" + e.statusText.toLowerCase() + ").", "", true);
			}
			// remove loader
			loader.hide();
		}
	});
	let destinationId = form.serialize().split('&')[3];
	if (destinationId && destinationId != '' && destinationId.includes('destination=')) {
		destinationId = destinationId.split('=')[1];
		getSearchBarMessage(folksSelectorWrapper, destinationId);
	}


}

const setSearchModuleOptions = function(response, dateField, durationSelect, enable, folksSelectorWrapper) {

	var availableDates = [];

	if (response.Success) {
		if (folksSelectorWrapper !== null) {
			updateOccupancyLimits(folksSelectorWrapper, response);
		}

		availableDates = makeAvailableDates(response);
		dateField.datepicker("option", "beforeShowDay", checkAvailableDate);

		if (folksSelectorWrapper.is(':visible')) {
			$("body").trigger({
				type: "availableDatesLoaded",
				availableDates: availableDates
			});
		} else {
			$("body").trigger({
				type: "availableDatesLoaded",
				availableDates: availableDates
			});
		}

		updateDurationOptions(durationSelect);

		if (enable) { dateField.removeAttr("disabled"); }
		setTimeout(function() {
			window.site.doc.trigger({
				type: "searchOptionsReceived",
				wrapper: folksSelectorWrapper,
				options: response
			});
		}, 100);

	} else {
		confirm("Error", "Sorry, there was an error (no dates found). Please try again.", "", true);
	}

	function updateOccupancyLimits(folksSelectorWrapper, response) {
		setOccupancyLimits(folksSelectorWrapper, response.MinOccupancy, response.MaxOccupancy, response.AbsoluteMaxChildOccupancy);
		setMaxRooms(folksSelectorWrapper, response.MaxRooms);
	}

	function makeAvailableDates(response) {
		var dates = [];
		for (var i = 0; i < response.Departing.length; i++) {
			var d = new Date(response.Departing[i]).getTime();
			dates.push(d);
		}
		return dates;
	}

	function checkAvailableDate(date) {
		var ret = [true, "", ""];
		if ($.inArray(date.getTime(), availableDates) === -1) {
			ret[0] = false;
			ret[1] = ""; // Custom disabled CSS class
			ret[2] = "Date not available"; // Custom message
		}
		return ret;
	}

	function updateDurationOptions(durationSelect) {
		durationSelect.find("option").each(function() {
			var option = $(this);
			if (option.val() === '' || $.inArray(parseInt(option.val()), response.Durations) > -1) {
				option.show();
			} else {
				option.hide();
			}
		});
	}
}


const getSearchBarMessage = function(folksSelectorWrapper, destinationId) {
	if (destinationId === '') {
		return;
	}

	$.ajax({
		url: "/SearchBarMessage/Get?destinationId=" + destinationId,
		success: function(response) {
			setSearchBarMessageDisplay(folksSelectorWrapper, response);
		},
		error: function(e) {
			console.log(e);
		}
	});
}

const setSearchBarMessageDisplay = function(folksSelectorWrapper, response) {
	if (response.message) {
		folksSelectorWrapper.find('.js-search-bar-message-text').text(response.message);
		folksSelectorWrapper.find('.js-search-bar-message').addClass('is-active');
		return;
	}
	folksSelectorWrapper.find('.js-search-bar-message').removeClass('is-active');
}

const searchBlockPickerOpen = function(trigger) {
	if (trigger.parents('.l-nav').length === 0) {
		window.site.html.addClass('search-picker-open--nav');
	}
}

const searchBlockPickerClose = function() {
	window.site.html.removeClass('search-picker-open--nav');
}

export { searchBlockPickerClose, searchBlockPickerOpen, updateSearchBarOptions, getSearchBarOptions, updatetriggerSearchAfterUpdateValue }
